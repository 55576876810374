@font-face {
  font-family: 'Acid Grotesk';
  src: url('../assets/fonts/AcidGrotesk-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Acid Grotesk';
  src: url('../assets/fonts/AcidGrotesk-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
